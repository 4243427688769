import { defineStore } from "pinia";
import { BrandsResponse } from "~/types/ProductManagement/Brand";
import {
  FilterPrice,
  FiltersStateType,
  FilterResponse,
} from "~/types/ProductManagement/filter";
import { useCategoriesStore } from "./categoriesStore";
const MAX_NUM_OPTION = 3;

export const useFiltersStore = defineStore("filters-store", () => {
  const filters = ref<FiltersStateType>();
  const optionsFilter = ref<FiltersStateType>();
  const priceFilter = ref<FilterPrice>();

  const { fetchCategories, fetchSubCategories, fetchCollections } =
    useCategoriesStore();
  const nuxtApp = useNuxtApp();
  const route = useRoute();
  const router = useRouter();

  const appliedFilters = computed(() => {
    if (filters.value && optionsFilter.value) {
      const allFilters = Object.values({
        ...filters.value,
        ...optionsFilter.value,
      });

      return allFilters.reduce(
        (acc, filterValues) => [
          ...acc,
          ...filterValues.filter((item) => item.isApplied),
        ],
        [],
      );
    } else {
      return [];
    }
  });

  const displayedOptionsFilter = computed(() => {
    const newOptionFilters = appliedFilters.value.reduce((acc, filter) => {
      if (filter.type === "OPTION" && optionsFilter.value?.[filter.key]) {
        acc[filter.key] = optionsFilter.value[filter.key];
      }
      return acc;
    }, {} as FiltersStateType);

    if (Object.values(newOptionFilters).length == MAX_NUM_OPTION) {
      return newOptionFilters;
    } else {
      return optionsFilter.value;
    }
  });

  const isApplied = (key: string, value: string) => {
    const queries = route.query[key] as string;

    if (queries) {
      return queries.split(",").includes(value);
    } else {
      return false;
    }
  };

  const fetchFilters = async () => {
    const { res } = await useApi<FilterResponse>(`/api/v2/filters`);

    priceFilter.value = res?.data.price;

    optionsFilter.value = res?.data.options.reduce((acc, option) => {
      return {
        ...acc,
        [option.title]: option.option_values.map((optionValue) => ({
          key: option.title,
          sectionTitle: option.title,
          type: "OPTION",
          label: optionValue.title,
          value: optionValue.id,
          color: optionValue.value,
          isApplied: isApplied("options", optionValue.id.toString()),
        })),
      };
    }, {});

    const brandsFilter = await fetchBrands();
    const categoriesFilter = await getCategories();
    const subCategoriesFilter = await getSubcategories();
    const collectionsFilter = await getCollections();

    // filter out undefined props
    filters.value = Object.fromEntries(
      Object.entries({
        categories: categoriesFilter,
        sub_categories: subCategoriesFilter,
        collections: collectionsFilter,
        brands: brandsFilter,
      }).filter(([_, value]) => !!value),
    ) as FiltersStateType;
  };

  const getCategories = async () => {
    const res = await fetchCategories();

    if (res && res.length) {
      const categoriesFilter = res.map((category) => ({
        key: "categories",
        sectionTitle: "categories",
        label: category.title,
        value: category.id,
        isApplied: isApplied("categories", category.id),
      }));

      return categoriesFilter;
    }
  };

  const getSubcategories = async () => {
    const res = await fetchSubCategories();

    if (res && res.length) {
      const subcategories = res.map((subcategory) => ({
        key: "sub_categories",
        sectionTitle: "sub categories",
        label: subcategory.title,
        value: subcategory.id,
        isApplied: isApplied("sub_categories", subcategory.id),
      }));

      return subcategories;
    }
  };

  const getCollections = async () => {
    const res = await fetchCollections();

    if (res && res.length) {
      const collections = res.map((collection) => ({
        key: "collections",
        sectionTitle: "Collections",
        label: collection.title,
        value: collection.id,
        isApplied: isApplied("collections", collection.id),
      }));

      return collections;
    }
  };

  const fetchBrands = async () => {
    if (nuxtApp.$features?.brands) {
      const { res } = await useApi<BrandsResponse>(`/api/v1/brands`);

      const brandsFilter = res?.data.map((brand) => ({
        key: "brands",
        sectionTitle: "Brands",
        label: brand.title,
        value: brand.id,
        isApplied: isApplied("brands", brand.id),
      }));

      if (
        brandsFilter?.length === 0 &&
        !nuxtApp.$features?.categories?.configs?.showEmptyCategories
      ) {
        return undefined;
      } else {
        return brandsFilter;
      }
    }
  };

  const applyFilter = (key: string, value: string) => {
    const filterList = filters.value?.[key];
    const optionList = optionsFilter.value?.[key];

    if (filterList) {
      const filterIndex = filterList.findIndex(
        (filter) => filter.value === value,
      );
      filterList[filterIndex].isApplied = !filterList[filterIndex].isApplied;
    }

    if (optionList) {
      const optionIndex = optionList.findIndex(
        (filter) => filter.value === value,
      );
      optionList[optionIndex].isApplied = !optionList[optionIndex].isApplied;
    }

    saveAppliedFilters();
  };

  const saveAppliedFilters = () => {
    const query: any = { page: 1 };

    const filtersList = filters.value;
    if (filtersList) {
      Object.keys(filtersList).map((key) => {
        const temp = filtersList[key]
          .filter((item) => item.isApplied)
          .map((item) => item.value)
          .join(",");

        if (temp !== "") {
          query[key] = temp;
        } else {
          query[key] = undefined;
        }
      });
    }

    const optionsList = optionsFilter.value;
    if (optionsList) {
      const optionsValue = Object.values(optionsList).reduce(
        (acc, filterValue) => {
          const temp = filterValue
            .filter((item) => item.isApplied)
            .map((item) => item.value)
            .join("|");

          if (temp !== "" && acc === "") {
            return acc + temp;
          } else if (temp !== "") {
            return acc + "," + temp;
          } else {
            return acc;
          }
        },
        "",
      );

      if (optionsValue !== "") {
        query.options = optionsValue;
      } else {
        query.options = undefined;
      }
    }

    router.replace({
      path: router.currentRoute.value.path,
      query: {
        ...router.currentRoute.value.query,
        ...query,
      },
    });
  };

  const clearAllFilters = () => {
    const updatedFilters: FiltersStateType = {};
    for (const key in filters.value) {
      updatedFilters[key] = filters.value[key].map((filter) => ({
        ...filter,
        isApplied: false,
      }));
    }

    filters.value = updatedFilters;

    const updatedOptionsFilter: FiltersStateType = {};
    for (const key in optionsFilter.value) {
      updatedOptionsFilter[key] = optionsFilter.value[key].map((option) => ({
        ...option,
        isApplied: false,
      }));
    }

    optionsFilter.value = updatedOptionsFilter;

    saveAppliedFilters();
  };

  const updateFilters = () => {
    const updatedFilters: FiltersStateType = {};
    for (const key in filters.value) {
      updatedFilters[key] = filters.value[key].map((filter) => ({
        ...filter,
        isApplied: isApplied(filter.key, filter.value),
      }));
    }

    filters.value = updatedFilters;
  };

  return {
    filters,
    optionsFilter,
    priceFilter,
    appliedFilters,
    displayedOptionsFilter,
    applyFilter,
    isApplied,
    clearAllFilters,
    updateFilters,
    fetchFilters,
  };
});
