import { defineStore } from "pinia";
import {
  CategoriesResponse,
  Category,
} from "~/types/ProductManagement/Categories";

type FetchCategoriesOptions = {
  include_media?: boolean;
  filter?: { name: "category"; itemId: string };
};

export const useCategoriesStore = defineStore("categories-store", () => {
  const categories = ref<Category[] | undefined>();
  const subcategories = ref<Category[] | undefined>();
  const collections = ref<Category[] | undefined>();

  const nuxtApp = useNuxtApp();

  const fetchCategories = async (options?: FetchCategoriesOptions) => {
    if (nuxtApp.$features?.categories) {
      const query = options?.include_media ? "?include=media" : "/roots";
      const filter = options?.filter
        ? `${options?.include_media ? "&" : ""}filter[${options.filter.name}]=${options.filter.itemId}`
        : "";

      const { res } = await useApi<CategoriesResponse>(
        `/api/v2/categories${query}${filter}`,
      );

      if (
        nuxtApp.$features.categories.configs?.showEmptyCategories ||
        (res?.data && res.data.length > 0)
      ) {
        categories.value = res?.data;
        return res?.data;
      }
    }
  };

  const fetchSubCategories = async (options?: FetchCategoriesOptions) => {
    if (
      nuxtApp.$features?.categories &&
      nuxtApp.$features?.categories?.configs?.haveSubCategories
    ) {
      const query = options?.include_media ? "?include=media" : "";
      const filter = options?.filter
        ? `${options?.include_media ? "&" : ""}filter[${options.filter.name}]=${options.filter.itemId}`
        : "";

      const { res } = await useApi<CategoriesResponse>(
        `/api/v2/categories/subcategories${query}${filter}`,
      );

      if (
        nuxtApp.$features.categories.configs?.showEmptyCategories ||
        (res?.data && res.data.length > 0)
      ) {
        subcategories.value = res?.data;
        return res?.data;
      }
    }
  };

  const fetchCollections = async (options?: FetchCategoriesOptions) => {
    if (
      nuxtApp.$features?.categories &&
      nuxtApp.$features?.categories?.configs?.haveCollections
    ) {
      const query = options?.include_media ? "?include=media" : "";
      const filter = options?.filter
        ? `${options?.include_media ? "&" : ""}filter[${options.filter.name}]=${options.filter.itemId}`
        : "";

      const { res } = await useApi<CategoriesResponse>(
        `/api/v2/categories/collections${query}${filter}`,
      );
      if (
        nuxtApp.$features.categories.configs?.showEmptyCategories ||
        (res?.data && res.data.length > 0)
      ) {
        collections.value = res?.data;

        return res?.data;
      }
    }
  };

  return {
    categories,
    subcategories,
    collections,
    fetchCategories,
    fetchSubCategories,
    fetchCollections,
  };
});
